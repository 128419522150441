import Link from "next/link";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw"; // Import rehypeRaw
import { Button } from "@/components/ui/button"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card"
import { researcherLite } from "@/lib/agents/researcher-lite";

const NonMemoizedMarkdown = ({ children }: { children: string }) => {
  const components = {
    code: ({ node, inline, className, children, ...props }: any) => {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <pre
          {...props}
          className={`${className} text-sm w-[80dvw] md:max-w-[500px] overflow-x-scroll bg-zinc-100 p-2 rounded mt-2 dark:bg-zinc-800`}
        >
          <code className={match[1]}>{children}</code>
        </pre>
      ) : (
        <code
          className={`${className} text-sm bg-red-100 dark:bg-zinc-800 py-0.5 px-1 rounded`}
          {...props}
        >
          {children}
        </code>
      );
    },
    ol: ({ node, children, ...props }: any) => {
      return (
        <ol className="list-decimal list-outside ml-4" {...props}>
          {children}
        </ol>
      );
    },
    li: ({ node, children, ...props }: any) => {
      return (
        <li className="py-1" {...props}>
          {children}
        </li>
      );
    },
    ul: ({ node, children, ...props }: any) => {
      return (
        <ul className="list-decimal list-outside ml-4" {...props}>
          {children}
        </ul>
      );
    },
    strong: ({ node, children, ...props }: any) => {
      return (
        <span className="font-semibold" {...props}>
          {children}
        </span>
      );
    },
    span: ({ node, children, ...props }: any) => {
      const [searchResult, setSearchResult] = useState<any>(null);
      const [loading, setLoading] = useState(false);
    
      const goFind = async (word : string) => {
        setLoading(true);
        try {
          const search = await researcherLite([
            {
              role: 'user',
              content: word,
            },
          ]);
          setSearchResult(search.response || 'No results found.');
        } catch (error) {
          setSearchResult('An error occurred while fetching data.');
        } finally {
          setLoading(false);
        }
      };
      
      return (
        <HoverCard>
          <HoverCardTrigger asChild onMouseEnter={() => goFind(children)}>
            <span className="font-semibold text-blue-600" {...props}>
              {children}
            </span>
          </HoverCardTrigger>
          <HoverCardContent className="w-80">
            <div className="flex justify-between space-x-4">
              <div className="space-y-1">
                <h3 className="text-lg font-bold text-gray-800 underline decoration-blue-600">{children}</h3>
                <p className="text-sm">
                {loading
                ? 'Loading...'
                : (
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]} // Add rehypeRaw to rehypePlugins
                    components={components}
                    // allowDangerousHtml // Allow raw HTML
                  >
                    {searchResult}
                  </ReactMarkdown>
                )}
                </p>
                {/* <div className="flex items-center pt-2">
                  <span className="text-xs text-muted-foreground">
                    Joined December 2021
                  </span>
                </div> */}
              </div>
            </div>
          </HoverCardContent>
        </HoverCard>
      );
    },
    a: ({ node, children, ...props }: any) => {
      return (
        <Link
          className="text-blue-500 hover:underline"
          target="_blank"
          rel="noreferrer"
          {...props}
        >
          {children}
        </Link>
      );
    },
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]} // Add rehypeRaw to rehypePlugins
      components={components}
      // allowDangerousHtml // Allow raw HTML
    >
      {children}
    </ReactMarkdown>
  );
};

export const Markdown = React.memo(
  NonMemoizedMarkdown,
  (prevProps, nextProps) => prevProps.children === nextProps.children,
);
