"use client";

import React, { useEffect, useState } from "react";
import { PartialTeaching } from "@/lib/schema/outputs";
import { Card } from "./ui/card";
import { Button } from "./ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { BotMessage } from "./message";
import {
  Sparkles,
  Image,
  TvMinimalPlay,
  ListOrdered,
  Volume2,
  Dog,
  Globe,
  ThumbsUpIcon,
  ThumbsDownIcon
} from "lucide-react";
import {
  StreamableValue,
  useActions,
  useStreamableValue,
  useUIState,
} from "ai/rsc";
import type { AI } from "@/app/actions";
import { useAppState } from "@/lib/utils/app-state";
import { UserMessage } from "./user-message";
import { generateId } from "ai";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from "@/components/ui/tooltip";
import { Toaster, toast } from 'sonner';
import { Varela_Round } from "next/font/google";
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";

const varela = Varela_Round({
  weight: "400",
  subsets: ["latin"],
});

type TooltipButtonProps = {
  children: React.ReactNode;
  tooltipText: string;
};

const TooltipButton: React.FC<TooltipButtonProps> = ({
  children,
  tooltipText,
}) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent>
          <span>{tooltipText}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export type CopilotProps = {
  response?: StreamableValue<PartialTeaching>;
};

export const CopilotTeach: React.FC<CopilotProps> = ({ response }) => {
  const [data, error, pending] = useStreamableValue<PartialTeaching>(response);
  const [object, setObject] = useState<PartialTeaching>();
  const [, setMessages] = useUIState<typeof AI>();
  const { submit } = useActions();
  const { setIsGenerating, setAutoScrollMode } = useAppState();
  const [itStarted, setItStarted] = useState(false);
  const [rated , setRated] = useState<any>(false);
  const { status } = useSession();
  const router = useRouter();

  useEffect(() => {
    if (!data) return;
    setObject(data);
    setItStarted(true);
  }, [data]);

  useEffect(() => {
    if (!pending) {
      setIsGenerating(false);
    }
  }, [pending]);

  const onFormSubmit = async (value: string) => {
    setIsGenerating(true);
    setAutoScrollMode(true);

    setMessages((currentMessages: any) => [
      ...currentMessages,
      {
        id: generateId(),
        component: <UserMessage message={value} />,
      },
    ]);

    const data = {
      userInput: {
        role: "user",
        content: value,
      },
    };
    const response = await submit(data);
    setMessages((currentMessages) => [...currentMessages, response]);
  };

  const rateResponse = async ({ id , rate } : {id : string, rate : string}) => {
    setRated(rate)
    toast.success('Thank you for your feedback!')
    
    await fetch("/api/messages", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: data?.messageId,
        rateType: rate
      }),
    });
  }

  const disableButtons = pending || status !== "authenticated";

  if (error) {
    return (
      <Card className="p-4 w-full flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Sparkles className="w-4 h-4" />
          <h5 className="text-muted-foreground text-xs truncate">
            {`error: ${error}`}
          </h5>
        </div>
      </Card>
    );
  }

  return (
    <div className={`${varela.className}`}>
      <Toaster position="top-right" richColors />
      {itStarted ? (
        <Card className="p-5 rounded-xl w-full mx-auto bg-white shadow-lg transition-all">
          <div
            className={`ml-2 ${pending ? "text-red-600" : "text-green-600"}`}
          >
            <Dog size={16} className="mr-1" />
          </div>

          <div
            className={object?.subject ? "mb-3 border-b pb-3 border-gray-300" : ""}
          >
            <h2 className="text-lg font-bold text-gray-800 ml-2">
              {object?.subject}
            </h2>
            <p className="text-sm text-gray-600 ml-2">{object?.topics}</p>
          </div>

          <div className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
              {object?.visuals?.map((visual, visualIndex) => (
                <div key={`visual-${visualIndex}`}>
                  <a
                    href={visual?.image_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={visual?.image_url}
                      alt={visual?.description}
                      className="w-full rounded-md transition-transform transform hover:scale-150 duration-300"
                      onError={(e) =>
                        (e.currentTarget.src =
                          "https://files.ds106.us/wp-content/uploads/sites/4/2014/12/placeholder.png")
                      }
                    />
                  </a>
                  <p className="text-xs text-gray-500 italic mt-2">
                    <BotMessage content={visual?.description as string} />
                  </p>
                </div>
              ))}
            </div>

            {object?.explanation?.map((explanation, index) => (
              <div
                key={`explanation-${index}`}
                className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors relative group"
              >
                <div className="mb-2">
                  <h3 className="text-md font-bold text-gray-800">
                    <BotMessage content={explanation?.title as string} />
                  </h3>
                  <p className="text-sm text-gray-600 italic">
                    <BotMessage content={explanation?.lecture as string} />
                  </p>
                </div>
                <div className="pl-4 mt-2 space-y-2 border-l-4 border-blue-400">
                  {explanation?.breakdowns?.map(
                    (breakdown, breakdownIndex) => (
                      <div
                        key={`breakdown-${breakdownIndex}`}
                        className="text-sm text-gray-700"
                      >
                        <p className="flex items-center">
                          <span className="mr-1 font-medium text-blue-800">
                            {breakdown?.step}:
                          </span>
                          <span className="flex-1">
                            <BotMessage
                              content={breakdown?.description as string}
                            />
                          </span>
                        </p>

                        {/* {breakdown?.visuals &&
                          breakdown?.visuals.map((visual, visualIndex) => (
                            <div
                              key={`visual-${visualIndex}`}
                              className="flex items-start space-x-4 mb-4"
                            >
                              <div className="w-1/6 flex-shrink-0">
                                <a
                                  href={visual?.visual}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={visual?.visual}
                                    alt={visual?.description}
                                    className="rounded-md transition-transform transform hover:scale-150 duration-300"
                                    onError={(e) =>
                                      (e.currentTarget.src =
                                        "https://files.ds106.us/wp-content/uploads/sites/4/2014/12/placeholder.png")
                                    }
                                  />
                                </a>
                              </div>

                              <p className="text-xs text-gray-500 italic mt-2">
                                <BotMessage
                                  content={breakdown?.description as string}
                                />
                              </p>
                            </div>
                          ))} */}
                      </div>
                    )
                  )}
                </div>
                {explanation?.answer && (
                  <div className="mt-4 flex items-center">
                    <p className="text-md font-semibold text-gray-800 flex">
                      <span className="mr-1 text-blue-500 font-medium">
                        Answer:
                      </span>
                      <BotMessage content={explanation?.answer as string} />
                    </p>
                  </div>
                )}

                <div className="flex flex-col items-end space-y-2 mt-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute bottom-4 right-4">
                  <TooltipButton tooltipText="Get Answers in Lecture Format!">
                    <div className="p-[1.5px] bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg inline-flex w-fit justify-end">
                      <Button
                        type="button"
                        variant="outline"
                        className="flex items-center px-3 py-2 bg-white rounded-md border-none"
                        disabled={disableButtons}
                        onClick={() =>
                          onFormSubmit(`Lecture me on ${explanation?.title}`)
                        }
                      >
                        <Volume2 size={16} className="mr-1" />
                        Lecture
                      </Button>
                    </div>
                  </TooltipButton>
                  
                  {status !== "authenticated" && (
                    <div className="flex items-center justify-end mt-4">
                      <span
                        className="bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent cursor-pointer"
                        onClick={() => router.push("/auth")}
                      >
                        Login
                      </span>
                      <span className="ml-1">to enable functions</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {!pending && (
            <>
              <div className="flex flex-col sm:flex-row sm:justify-between mt-4">
                <div className="flex flex-row sm:space-x-1 ml-2">
                  <TooltipButton tooltipText="Helpful">
                    <div
                      className={`flex items-center cursor-pointer p-1 rounded-full text-slate-600 hover:text-yellow-600 ${rated == 'up' && 'text-yellow-600'}`}
                      // disabled={disableButtons}
                      onClick={() =>
                        rateResponse({
                          id: '',
                          rate: 'up',
                        })
                      }
                    >
                      <ThumbsUpIcon size={16} />
                    </div>
                  </TooltipButton>
                  <TooltipButton tooltipText="Not helpful">
                    <div
                      className={`flex items-center cursor-pointer p-1 rounded-full text-slate-600 hover:text-yellow-600 ${rated == 'down' && 'text-yellow-600'}`}
                      // disabled={disableButtons}
                      onClick={() =>
                        rateResponse({
                          id: '',
                          rate: 'down',
                        })
                      }
                    >
                      <ThumbsDownIcon size={16} />
                    </div>
                  </TooltipButton>
                </div>
                <div className="flex flex-col sm:flex-row sm:justify-end sm:space-x-2 mt-2">
                  <TooltipButton tooltipText="Get Answers in Lecture Format!">
                    <Button
                      type="button"
                      variant="outline"
                      className="flex items-center px-3 py-2 mt-2 sm:mt-0"
                      disabled={disableButtons}
                      onClick={() => onFormSubmit("Lecture me")}
                    >
                      <Volume2 size={16} className="mr-1" />
                      Lecture
                    </Button>
                  </TooltipButton>

                  <TooltipButton tooltipText="Get Relevant Videos!">
                    <Button
                      type="button"
                      variant="outline"
                      className="flex items-center px-3 py-2 mt-2 sm:mt-0"
                      disabled={disableButtons}
                      onClick={() =>
                        onFormSubmit(
                          `Find videos for: ${object?.videosSearchButton}`
                        )
                      }
                    >
                      <TvMinimalPlay size={16} className="mr-1" />
                      Videos
                    </Button>
                  </TooltipButton>

                  <TooltipButton tooltipText="Get Most Relevant Images!">
                    <Button
                      type="button"
                      variant="outline"
                      className="flex items-center px-3 py-2 mt-2 sm:mt-0"
                      disabled={disableButtons}
                      onClick={() =>
                        onFormSubmit(`Find images for: ${object?.subject}`)
                      }
                    >
                      <Image size={16} className="mr-1" />
                      Images
                    </Button>
                  </TooltipButton>

                  <TooltipButton tooltipText="Search the web!">
                    <Button
                      type="button"
                      variant="outline"
                      className="flex items-center px-3 py-2 mt-2 sm:mt-0"
                      disabled={disableButtons}
                      onClick={() =>
                        onFormSubmit(
                          `Web search: ${object?.subject}`
                        )
                      }
                    >
                      <Globe size={16} className="mr-1" />
                      Web
                    </Button>
                  </TooltipButton>
                </div>
              </div>
            </>
          )}

          {object?.followUpRequests && (
            <div className="flex flex-col mt-6">
              <span className="text-lg w-full text-blue-500">
                You may also want to ask
              </span>
              <div className="flex items-center flex-wrap space-x-2 mt-2">
                {object?.followUpRequests?.map((followup, index) => (
                  <Button
                    key={index}
                    type="button"
                    variant="outline"
                    className="flex items-center mt-2 ml-1 px-4 py-2 border-gray-300 text-gray-700 rounded hover:bg-gray-100 whitespace-normal break-words"
                    disabled={disableButtons}
                    onClick={() =>
                      onFormSubmit(followup?.request as string)
                    }
                  >
                    <ListOrdered size={16} className="mr-2" />
                    <BotMessage content={followup?.request as string} />
                  </Button>
                ))}
              </div>
              {status !== "authenticated" && (
                <div className="flex items-center justify-end mt-4">
                  <span
                    className="bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent cursor-pointer"
                    onClick={() => router.push("/auth")}
                  >
                    Login
                  </span>
                  <span className="ml-1">to enable functions</span>
                </div>
              )}
            </div>
          )}
        </Card>
      ) : (
        <div className="flex flex-col gap-2 py-2">
          <Skeleton className="h-6 w-48" />
          <Skeleton className="w-full h-6" />
        </div>
      )}
    </div>
  );
};
